jQuery(document).ready(function($) {
	
	/*******************************************************
	Карусели
	******************************************************/
	
	var sync1 = jQuery(".home-slider-bg"), 
	sync2 = jQuery(".home-slider"), 
	flag = false, 
	duration = 800, 
	rtlt = eval(jQuery(this).data("rtlt"));
    var sync2_ops = sync2.data('options') ? sync2.data('options') : {smartSpeed:1200,autoplay:false,autoplaySpeed: 5600,items:1,autoplayTimeout:6000,responsive:false,loop:false,dots:true,center:false};
    if(sync2_ops.responsive){
        var respArr = sync2_ops.responsive.split(',');
        sync2_ops.responsive = {};
        for (var i = 0; i < respArr.length ; i++){
            var tempVal = respArr[i].split(':');
            sync2_ops.responsive[tempVal[0]] = tempVal[1];

        }
    }else{
        sync2_ops.responsive = false;
    }
    sync1.owlCarousel({
        loop: true,
        margin: 0,
        nav: false,
        items: 1,
        dots: false,
        smartSpeed: 1200,
        autoHeight: false,
        autoplay: false,
        autoplayTimeout: sync2_ops.autoplayTimeout,
        autoplaySpeed: sync2_ops.autoplaySpeed,
        responsive:sync2_ops.responsive,
        center:sync2_ops.center,
        autoWidth:false,
    })/*.on("changed.owl.carousel", function(a) {
        if (!flag) {
            flag = true;
            sync2.trigger("to.owl.carousel", [ a.item.index, duration, true ]);
            flag = false;
        }
    });*/
	.on("changed.owl.carousel", function(a) {
		//console.log("sync1 moved to" + a.item.index);
	});
    sync2.owlCarousel({
        loop: true,
        margin: 0,
        nav: true,
		navText: false,
        items: sync2_ops.items,
        dots: true,
        smartSpeed: sync2_ops.smartSpeed,
        autoHeight: false,
        autoplay: true,
        autoplayTimeout: 4000,
        responsive:sync2_ops.responsive,
        center:sync2_ops.center,
        autoWidth:false,
		stopOnHover: false,
    }).on("changed.owl.carousel", function(a) {
        if (!flag) {
            flag = true;
			//console.log("sync2 moved to" + a.item.index);
            sync1.trigger("to.owl.carousel", [ a.item.index - 2, duration, true ]);
            flag = false;
        }
    });
	
	
    $('.brands-carousel').owlCarousel({
		loop:true,
		margin:25,
		nav:true,
		navText: false,
		dots: false,
		items: 6,
		responsiveClass:true,
		slideBy: 'page',
		responsive:{
			0:{
				items:2
			},
			480:{
				items:3
			},
			640:{
				items:4
			},
			800:{
				items:5
			},
			960:{
				items:6
			}
		}
	});
	
	 $('.news-carousel').owlCarousel({
		loop:true,
		margin:70,
		nav:true,
		navText: false,
		dots: false,
		items: 3,
		responsiveClass:true,
		slideBy: 'page',
		responsive:{
			0:{
				items:1
			},
			480:{
				items:2,
				margin: 15
			},
			800:{
				items:3
			}
		}
	});
	
	
	/*******************************************************
	Меню аккордион
	******************************************************/
	$('.sidebar-menu .has-submenu.active ul').velocity("slideDown", { duration: 0 });
	
	$('.sidebar-menu .has-submenu > a').click(function(e) {
		e.preventDefault();
		var targetParent = $(this).parent(),
		targetUl = $(this).next('ul');
		$(targetParent).siblings().removeClass('active');
		$(targetParent).siblings().children('ul').velocity("slideUp", { duration: 300 });
		if($(targetUl).is(':visible')) {
			$(targetUl).velocity("slideUp", { duration: 300 });
			$(targetParent).removeClass('active');
		} else {
			$(targetUl).velocity("slideDown", { duration: 300 });
			$(targetParent).addClass('active');
		}
		return false;
	});
	
	/*******************************************************
	Мобильное меню
	******************************************************/
	$('#toggle_nav').on("click", function() {
		if($('.nav-menu ul').is(':visible')) {
			$('#toggle_nav').removeClass('active');
			$('.nav-menu ul').velocity("slideUp", { duration: 300 });
		} else {
			$('#toggle_nav').addClass('active');
			$('.nav-menu ul').velocity("slideDown", { duration: 300 });
		}
	});
	
	
	/*******************************************************
	Мобильные таблицы
	******************************************************/
	if ($.fn.basictable) {
		$('.responsive-table').basictable({
			breakpoint: 959
		});
	}
	
});